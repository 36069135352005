import { DocumentType } from '@innedit/innedit-type';
import React, { FC, useEffect, useState } from 'react';

import { ListItemProps } from '../../../../containers/Schema/props';
import IconEye from '../../../../icons/Eye';
import IconEyeSlash from '../../../../icons/EyeSlash';
import ListItem from '../index';

const ListItemSchemaCollection: FC<ListItemProps<any>> = ({
  docId,
  index,
  onClick,
  model,
}) => {
  const [doc, setDoc] = useState<DocumentType<any>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  return (
    <ListItem
      contentClassName="flex-auto flex flex-row"
      doc={doc}
      index={index}
      onClick={onClick}
    >
      <div className="ml-3 flex items-center space-x-3">
        {!doc.hidden && <IconEye />}
        {doc.hidden && <IconEyeSlash />}
        <strong>{doc[model.itemFieldTitle]}</strong>
      </div>
    </ListItem>
  );
};

export default ListItemSchemaCollection;

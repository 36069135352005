import { ModelSchemaData, WatchOptionsProp } from '@innedit/innedit';
import { DocumentType, ModelType, UserType } from '@innedit/innedit-type';
import classnames from 'classnames';
import dayjs from 'dayjs';
import { navigate } from 'gatsby';
import compact from 'lodash/compact';
import mergeWith from 'lodash/mergeWith';
import React, {
  FC,
  ReactElement,
  SyntheticEvent,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import Button, { ButtonProps } from '../../../components/Button';
import { FilterType } from '../../../components/Filter';
import { MenuType } from '../../../components/Menu';
import ViewList from '../../../components/View/List';
import IconChevron from '../../../icons/Chevron';
import pathname from '../../../utils/pathname';
import { parse } from '../../../utils/queryString';
import { ListItemProps } from '../props';

export interface ListProps<T extends ModelType> {
  allowSorting?: boolean;
  createError?: string;
  createSuccess?: string;
  deleteOnClick?: (event: SyntheticEvent<HTMLButtonElement>) => void;
  displayDelete?: boolean;
  displayMode?: 'search' | 'watch';
  displayDuplicate?: boolean;
  displaySearch?: boolean;
  filters?: FilterType[];
  form?: FC<any>;
  formName?: string;
  itemGrid?: FC<ListItemProps<T>>;
  itemList: FC<ListItemProps<T>>;
  itemClassName?: string;
  itemLoading?: string;
  itemNothing?: string;
  itemPathnamePrefix: string;
  listClassName?: string;
  menu?: MenuType;
  model: ModelSchemaData<T>;
  nbParPage?: number;
  page?: number;
  q?: string;
  removeAddButton?: boolean;
  search: string;
  tabIndex?: number;
  title: string;
  user: DocumentType<UserType>;
}

const List = <T extends ModelType>({
  allowSorting = false,
  displayDelete,
  displayMode = 'watch',
  displaySearch,
  filters,
  itemGrid,
  itemList,
  itemLoading,
  itemNothing,
  itemPathnamePrefix,
  menu = { left: [], right: [] },
  model,
  nbParPage = 10,
  removeAddButton,
  search,
  tabIndex = 0,
  title,
  user,
}: ListProps<T>): ReactElement | null => {
  const [wheres, setWheres] = useState<{ [key: string]: any }>({});
  const [page, setPage] = useState<number>(0);
  const [q, setQ] = useState<string>('');

  const [hitsPerPage] = useState<number>(nbParPage);
  const [nbDocs, setNbDocs] = useState<number>();
  const [docs, setDocs] = useState<{ [key: string]: any }[]>();
  const { t } = useTranslation();
  const [itemMode, setItemMode] = useState<'grid' | 'list'>(
    (itemGrid && user.defaultListItemMode) || 'list',
  );

  // const wheresString = JSON.stringify(wheres);

  useEffect(() => {
    const params = parse(search);

    setPage(Number(params?.page) || 0);
    setQ((params?.q as string) || '');

    const newWheres: any = {};

    if (params) {
      Object.keys(params).forEach(param => {
        const filter = filters?.find(f => f.name === param);
        if (filter) {
          let value: any = params[param];

          if (!Array.isArray(params[param])) {
            if (['true', 'false'].includes(params[param] as string)) {
              value = 'true' === params[param];
            }
          } else {
            value = (params[param] as string[]).map(p => {
              if (['true', 'false'].includes(p)) {
                return 'true' === p;
              }

              return p;
            });
          }

          if ('array' === filter.type) {
            if (Array.isArray(params[param])) {
              newWheres[param] = {
                value,
                operator: 'array-contains-any',
              };
            } else {
              newWheres[param] = {
                value,
                operator: 'array-contains',
              };
            }
          } else if (Array.isArray(params[param])) {
            newWheres[param] = {
              value,
              operator: 'in',
            };
          } else {
            newWheres[param] = value;
          }
        }
      });
    }

    setWheres(newWheres);
  }, [search]);

  useEffect(() => {
    setItemMode((itemGrid && user.defaultListItemMode) || 'list');
  }, [user]);

  useEffect(() => {
    let isMount = true;
    let unsub: () => void;

    let wheresTab;
    let allowTabSorting = true;

    if (model.tabs && undefined !== tabIndex && model.tabs[tabIndex]) {
      wheresTab = model.tabs[tabIndex].wheres;
      allowTabSorting =
        undefined !== model.tabs[tabIndex].allowSorting
          ? Boolean(model.tabs[tabIndex].allowSorting)
          : true;
    }

    if (q || 'search' === displayMode) {
      model
        .search(q || '', {
          page,
          attributesToRetrieve: ['datetime', 'objectID', 'pathname'],
          nbParPage: hitsPerPage,
          responseFields: ['hits', 'nbHits'],
          wheres: mergeWith(wheresTab, wheres),
        })
        .then(result => {
          if (isMount) {
            setNbDocs(result.nbHits);

            setDocs(
              result.hits.map(hit => ({
                ...hit,
                id: hit.objectID,
              })),
            );
          }

          return true;
        })
        .catch(error => {
          toast.error(error.message);
          console.error(error.message);
        });
    } else {
      const watchOptions: WatchOptionsProp = {
        limit: (page + 1) * hitsPerPage,
        wheres: mergeWith(wheresTab, wheres),
      };

      if (allowTabSorting && allowSorting) {
        watchOptions.orderField = 'datetime';
      }

      unsub = model.watch(newDocs => {
        if (isMount) {
          setNbDocs(undefined);
          setDocs(newDocs.slice(page * hitsPerPage));
        }
      }, watchOptions);
    }

    return () => {
      isMount = false;
      if (unsub) {
        unsub();
      }
    };
  }, [
    allowSorting,
    displayMode,
    hitsPerPage,
    page,
    q,
    tabIndex,
    wheres,
    model.collectionName,
    model.parentId,
  ]);

  const handleOnClick = async (
    event: SyntheticEvent<HTMLElement>,
  ): Promise<void> => {
    const id = event.currentTarget.getAttribute('data-id');

    if (id) {
      const paths = compact(itemPathnamePrefix.split('/'));
      await navigate(`/${paths.join('/')}/${id}/update/`, {
        replace: false,
      });
    }
  };

  const handleChangePosition = async (
    oldIndex: number,
    newIndex: number,
  ): Promise<void> => {
    if (docs && newIndex - oldIndex !== 1) {
      let datetime = dayjs().valueOf();
      const newDatetime = docs[newIndex].datetime;

      if (0 < newIndex) {
        const beforeDatetime = docs[newIndex - 1].datetime;
        const diff = Math.floor((beforeDatetime - newDatetime) / 2);
        datetime = newDatetime + diff;
      }
      if (datetime === docs[newIndex].datetime) {
        toast.error(
          "le nouveau datetime est le même que celui de l'ancienne position",
        );
      }

      const oldId = docs[oldIndex].id;
      const document = await model.findById(oldId);
      if (document) {
        model
          .update(document.id, {
            datetime,
            updatedAt: dayjs().toISOString(),
          } as Partial<T>)
          .catch((error: Error) => {
            toast.error(error.message);
            console.error(error.message);
          });

        setDocs(oldDocs => {
          const newDocs = oldDocs ? [...oldDocs] : [];
          if (oldDocs && oldDocs.length > 0) {
            newDocs[oldIndex].datetime = datetime;
            const [tmpDoc] = newDocs.splice(oldIndex, 1);
            newDocs.splice(
              newIndex > oldIndex ? newIndex - 1 : newIndex,
              0,
              tmpDoc,
            );
          }

          return newDocs;
        });
      }
    }
  };

  const handleUpdateDocByIndex = (
    index: number,
    data: { [key: string]: any },
    newIndex?: number,
  ): void => {
    setDocs(oldDocs => {
      const newDocs = oldDocs ? [...oldDocs] : [];
      if (oldDocs && oldDocs.length > 0) {
        newDocs[index] = { ...newDocs[index], ...data };
        if (undefined !== newIndex) {
          const [tmpDoc] = newDocs.splice(index, 1);
          newDocs.splice(newIndex > index ? newIndex - 1 : newIndex, 0, tmpDoc);
        }
      }

      return newDocs;
    });
  };

  const defaultMenu: Required<MenuType> = {
    left: [],
    right: [],
  };

  if (displayDelete) {
    // defaultMenu.left.push({
    //   color: 'danger',
    //   iconLeft: IconDelete,
    //   onClick: handleDeleteOnClick,
    //   variant: 'outline',
    // });
  }

  const customizer = (
    objValue: ButtonProps[],
    srcValue: ButtonProps[],
  ): ButtonProps[] => [...srcValue, ...objValue];

  if (!removeAddButton) {
    defaultMenu.right.push({
      text: model.addButtonLabel,
      to: `${itemPathnamePrefix}create/`,
    });
  }

  const Item = itemGrid && 'grid' === itemMode ? itemGrid : itemList;

  const className =
    'grid' === itemMode
      ? 'grid grid-cols-2 md:grid-cols-4 gap-6 mx-6 pb-6'
      : '';

  return (
    <ViewList
      displaySearch={displaySearch}
      filters={filters}
      menu={mergeWith(defaultMenu, menu, customizer)}
      nbDocs={nbDocs}
      options={itemGrid && ['itemMode']}
      search={search}
      tabIndex={tabIndex}
      tabs={model.tabs}
      title={title}
      user={user}
    >
      {docs && docs.length > 0 && (
        <ul className={className}>
          {docs.map(({ id, _highlightResult: highlight }, i) => (
            <Item
              key={id}
              changePosition={allowSorting ? handleChangePosition : undefined}
              docId={id}
              highlight={highlight}
              index={i}
              model={model}
              onClick={handleOnClick}
              updateDocByIndex={handleUpdateDocByIndex}
            />
          ))}
        </ul>
      )}
      {docs && 0 === docs.length && (
        <div className="p-6 text-center">{t(itemNothing || 'nothing')}</div>
      )}
      {!docs && (
        <div className="p-6 text-center">{t(itemLoading || 'loading')}</div>
      )}

      {docs && docs.length > 0 && (page > 0 || docs.length === hitsPerPage) && (
        <div className="flex border-t justify-center mt-6 p-6">
          {page > 0 && (
            <Button
              className={classnames('transform rotate-180 ', {
                'border-l-0 rounded-l-none':
                  !nbDocs || hitsPerPage * (page + 1) < nbDocs,
              })}
              color="neutral"
              iconLeft={IconChevron}
              isInfinite
              size="sm"
              to={pathname({
                actions: [{ name: 'page', value: String(page - 1) }],
              })}
              variant="outline"
            />
          )}

          {(!nbDocs || hitsPerPage * (page + 1) < nbDocs) && (
            <Button
              className={classnames({
                'rounded-l-none': page > 0,
              })}
              color="neutral"
              iconLeft={IconChevron}
              isInfinite
              size="sm"
              to={pathname({
                actions: [{ name: 'page', value: String(page + 1) }],
              })}
              variant="outline"
            />
          )}
        </div>
      )}
    </ViewList>
  );
};

export default List;

import queryString, { ParsedQuery } from 'query-string';

export const parse = (str?: string): ParsedQuery => {
  if (!str) {
    return {};
  }

  return queryString.parse(str); // , { arrayFormat: 'comma' }

  // const newStr = ['#', '?'].includes(str.slice(0, 1)) ? str.substring(1) : str;
  //
  // return newStr.split('&').reduce((acc, s) => {
  //   if (s) {
  //     const [key, value] = s.split('=');
  //
  //     return {
  //       ...acc,
  //       [key]: decodeURI(value),
  //     };
  //   }
  //
  //   return acc;
  // }, {});
};

export const stringify = (params: {
  [key: string]: string | string[] | null;
}): string => queryString.stringify(params); // , { arrayFormat: 'comma' }

// Object.keys(params)
//   .reduce((acc, key) => {
//     if (params[key]) {
//       acc.push(`${key}=${params[key]}`);
//     }
//
//     return acc;
//   }, [] as string[])
//   .join('&');

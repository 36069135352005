import styled from '@emotion/styled';
import { DocumentType } from '@innedit/innedit-type';
import classnames from 'classnames';
import objectHash from 'object-hash';
import { rem } from 'polished';
import React, { FC, ReactNode, SyntheticEvent, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import IconEdit from '../../../icons/Edit';
import IconSort from '../../../icons/Sort';
import { borderRadius, colors, spacing } from '../../../styles/theme';
import Button, { ButtonEL, ButtonProps } from '../../Button';

const ItemTypes = {
  ITEM: 'item',
};

interface DragProduit {
  index: number;
  type: string;
}

const ItemSC = styled.li`
  display: flex;
  position: relative;

  padding: ${spacing[1.5]} 0 ${spacing[1.5]} ${spacing[6]};
  justify-content: space-between;
  min-height: ${rem(54)};
  box-sizing: border-box;

  .block {
    display: block;
    font-size: 80%;
  }

  .actions {
    display: flex;
    align-items: center;
    padding: 0 ${spacing[1.5]};
  }

  .field-actions {
    margin: 0;
  }

  & + & {
    border-top: 1px solid ${colors.light[500]};
  }

  &:nth-of-type(2n) {
    background: ${colors.light[300]};
  }

  // &:hover {
  //   background: ${colors.light[500]};
  // }

  &:last-child {
    border-bottom-left-radius: ${borderRadius.s};
    border-bottom-right-radius: ${borderRadius.s};
  }
`;

interface ListItemProps<T> {
  actions?: ButtonProps | ButtonProps[];
  after?: ReactNode;
  className?: string;
  changePosition?: (oldIndex: number, newIndex: number) => void;
  contentClassName?: string;
  displayActionId?: boolean;
  doc: DocumentType<T>;
  index?: number;
  onClick: (event: SyntheticEvent<HTMLButtonElement>) => void;
}

const ListItem: FC<ListItemProps<any>> = ({
  actions,
  after,
  changePosition,
  children,
  className,
  contentClassName,
  displayActionId = true,
  doc,
  index,
  onClick,
}) => {
  const ref = useRef<HTMLLIElement>(null);

  const [{ canDrop, handlerId, isOver }, drop] = useDrop({
    accept: ItemTypes.ITEM,
    collect: (monitor: any) => ({
      canDrop: monitor.canDrop(),
      handlerId: monitor.getHandlerId(),
      isOver: monitor.isOver(),
    }),
    drop(item: DragProduit) {
      if (!ref.current || undefined === index) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      if (changePosition) {
        changePosition(dragIndex, hoverIndex);
      }
    },
  });

  const [{ isDragging }, drag, previous] = useDrag({
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => ({ index, type: ItemTypes.ITEM }),
    type: ItemTypes.ITEM,
  });

  if (changePosition) {
    previous(drop(ref));
  }

  // 'flex border p-1.5 justify-between',
  return (
    <>
      <ItemSC
        ref={ref}
        className={classnames('items-center', className, {
          '!bg-primary-500': isDragging,
          '!bg-secondary-500': isOver,
          'bg-tertiary-500': canDrop,
        })}
        data-handler-id={handlerId}
      >
        <div
          className={classnames(
            'w-full',
            contentClassName || 'flex items-center',
          )}
        >
          {children}
        </div>
        <div className="actions space-x-1 ml-3 self-start">
          {actions &&
            (!Array.isArray(actions) ? [actions] : actions).map(
              ({ tooltip, text, type, ...others }) => (
                <Button
                  key={objectHash({ className, text, tooltip, type })}
                  color="neutral"
                  square
                  variant="ghost"
                  {...others}
                />
              ),
            )}
          {doc.id && displayActionId && (
            <Button
              color="neutral"
              square
              tooltip={doc.id}
              type="tooltip"
              variant="ghost"
            />
          )}
          {changePosition && (
            <ButtonEL ref={drag} color="neutral" square variant="ghost">
              <IconSort />
            </ButtonEL>
          )}
          <Button
            color="primary"
            data-id={doc.id}
            iconLeft={IconEdit}
            onClick={onClick}
            variant="ghost"
          />
        </div>
      </ItemSC>
      {after}
    </>
  );
};

export default ListItem;
